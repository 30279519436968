<template>
  <!-- 导航栏。阻止事件冒泡。 -->
  <div v-if="buttons.length!==0" v-on:click.stop class="navigation_bar_style" style="font-size:20px;display:flex;flex-direction:row;/*background-color:#FFFFFF;border-top:1px solid #EEEEEE;*/position:relative;z-index:999;cursor:pointer;">
    <!-- 再加一个底部边距，预防某些全屏手机遮挡底部。 -->
    <div v-for="(item,index) in buttons" :key="index" @click="myClick(item)" style="padding:16px 0;padding-bottom:32px;flex:1;display:flex;flex-direction:column;justify-content:center;align-items:center;">
      <!-- 按钮图片 -->
      <img v-if="(selectedWhoButton==item.id?item.selectedIconPath:item.iconPath)" alt="图" :src="selectedWhoButton==item.id?item.selectedIconPath:item.iconPath" style="display:block;width:44px;height:44px;" />
      <!-- 按钮名 -->
      <div v-if="item.name||item.name==0" :style="{color:(selectedWhoButton==item.id?nameSelectedColor:nameColor)}">{{item.name}}</div>
    </div>
  </div>
</template>

<script>

import {getCurrentInstance } from "vue";
import {mustExistInStart} from "@/utils/tool_zhang";

/**
 事件：
 selected
 
 ```js
 调用方法：
 
 <!-- 底部导航栏 -->
 <NavigationBar selectedWhoButton="{{selectedWhoButton}}" bind:selected="selected" nameColor="{{navigationBar_conf.style.nameColor}}" nameSelectedColor="{{navigationBar_conf.style.nameSelectedColor}}" buttons="{{navigationBar_conf.buttons}}" />
 
 selectedWhoButton：想要默认选中哪个key
 bind:selected：选中后的变化时间，上传key值
 nameColor：按钮名字的颜色
 nameSelectedColor：选中后的按钮名字的颜色
 buttons：按钮配置参数；数组
 
 
 buttons的格式：
 //一对一
 //底部导航栏的按钮数据
 buttons:[
 {
    id:"0",
    //若是单页面路由，则页面路径可无
    //pagePath: "/pages/index/index",
    name: "首页",
    iconPath: "/resource/image/icon/home_0.png",
    selectedIconPath: "/resource/image/icon/home_1.png"
  },{
    id:"1",
    //若是单页面路由，则页面路径可无
    //pagePath: "/pages/release/release",
    name: "发布",
    iconPath: "/resource/image/icon/add_0.png",
    selectedIconPath: "/resource/image/icon/add_1.png"
  },{
    id:"2",
    //若是单页面路由，则页面路径可无
    //pagePath: "/pages/my/my",
    name: "我的",
    iconPath: "/resource/image/icon/user_0.png",
    selectedIconPath: "/resource/image/icon/user_1.png"
  },
 ]
 
 
 样式类：navigation_bar_style
 
 
 更新时间：2024_5_19_星期日_02_36_36
 ```
 */
export default {
  name:"NavigationBar",
  props: {
    buttons:{  //多个按钮
      type: Array,
      default(){
        return [
          /* {
            id:"0",
            pagePath: "pages/index/index",
            name: "首页",
            iconPath: "resource/image/icon/index_0.png",
            selectedIconPath: "resource/image/icon/index_1.png"
          } */
        ];
      }
    },
    selectedWhoButton:{  //选中哪个按钮
      type:String,
      default:"0"
    },
    nameColor:{  //名字的颜色
      type:String,
      default:"#666666"
    },
    nameSelectedColor:{  //名字的选中颜色
      type:String,
      default:"#536DF9"
    },
    
  },
  
  setup(){
  
    const { proxy } = getCurrentInstance();
  
    
    //跳转到指定页面
    let goToPage=function(vv){
      //获取当前页路径
      let currentPath= window.location.pathname;
  
      //添加"/"
      currentPath=mustExistInStart(currentPath,"/");
      vv.path=mustExistInStart(vv.path,"/");
      
      //判断要跳转的页面路径 与 当前页面路径是否相同，不相同才允许跳转
      if(vv.path!=currentPath){
        //关闭当前页面，跳转到应用内的某个页面
  
        //切换到首页面
        proxy.$router.push(vv.path);
  
        //location.href =vv.path;
  
      }
      
    };
  
    //选中按钮
    let selectButton=function (vv){
      //触发指定事件：上传数据
      proxy.$emit("selected",{value:vv});
    }
    
    return {
      myClick(vv){
        
        (vv.pagePath?
            goToPage({path:vv.pagePath,data:null}):
            selectButton(vv.id)
        );
        
      }
    };
  }
}
</script>

<style scoped>

</style>
