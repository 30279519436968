import {createObject} from '@/utils/request'

//创造对象
const request=createObject("");

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: '/userInfo.json',
    method: 'get',
    params: data
  })
}
