<template>
  <div style="position:absolute;left:0;top:0;width:100%;height:100%;display:flex;flex-direction:column;background-color:#000000;color:#ffffff;font-family:sans-serif;">
  
    <!-- 卷轴域 -->
    <div class="hdt" style="flex:1;overflow-x:hidden;overflow-y:auto;">
  
      <router-view />
      
    </div>
    
    <!--<Load v-for="(value,key,index) in loadList" :key="index" :backgroundImage="value.backgroundImage" :background="value.background" :text="value.text" :isEventsPenetrate="value.isEventsPenetrate" />-->
  </div>
</template>

<script>
import {onMounted,provide,getCurrentInstance} from "vue";
// import {getUserInfo} from "@/api/webApi/local";
// import Load from "@/component/Load";


// import load_png from '@/assets/image/load.png'
// import {setLoadViewTool} from "@/utils/request";


export default {
  name: 'App',
  components: {
    // Load
  },
  data(){
    return {
      pageId:null,
      // load_png,
  
      loadList:{
        /*1:{
          key:1,
          hideLoad:()=>{}
          ...
        }*/
      },
  
  
    }
  },
  setup() {

    // 在应用实例上提供一个值
    provide('setTitle',function(text=""){
      document.getElementsByTagName("title")[0].innerText=text;
    });
  
  
  
    const { proxy } = getCurrentInstance();
  
    // 在应用实例上提供一个值
    provide('app',proxy);
  
    
    
    /*
    let showQuantity=0;  //显示量
  
    /!**隐藏加载视图*!/
    function hideLoad(){
      showQuantity--;
    
      //console.log("调试",this);
      
      delete proxy.loadList[this.key]
    
    }
  
    /!**
     创造加载视图
     <br />
     返回值：对象，可用于关闭当前的加载视图
     <p>示例：</p>
     Object.hideLoad();
     *!/
    function createLoad(data={}){
      showQuantity++;
  
      //默认值
      if(!data.backgroundImage) data["backgroundImage"]=load_png;
      
      let obj={
        key:showQuantity,
  
        ...data,
  
        hideLoad:hideLoad
      };
      proxy.loadList[showQuantity]=obj;
      
      return obj;
    }
  
  
    let loadViewTool={
      createLoad:createLoad,
    };
    // 在应用实例上提供一个值
    //加载视图的工具
    provide('loadViewTool',loadViewTool);
    setLoadViewTool(loadViewTool);
  */
    
  
    //从本地磁盘中获取参数
    let userData=localStorage.getItem("userData");
    //转成JS对象
    userData=JSON.parse(userData);
  
    
    //创造加载视图
    // let loadObject=null;
    
    onMounted(async () => {
    /*
      if(userData&&userData.name){
  
  
        //创造加载视图
        loadObject=createLoad();
  
        //验证用户信息
        getUserInfo().then(res=>{
          console.log("成功1：",res,userData);
        
          let length=res.length;
          //遍历
          for(let i=0;i<length;i++){
          
            if(userData.name==res[i].name&&userData.password==res[i].password){
              //隐藏加载视图
              loadObject.hideLoad();
  
              //切换到首页面
              //proxy.$router.push("/index");
            
              return;
            }
          
          }
  
  
          //隐藏加载视图
          loadObject.hideLoad();
  
          
          //没有一个能匹配得上的
          //切换到登录页面
          proxy.$router.push("/Login");
        
        }).catch((res)=>{
          console.log("网络异常，请重试！",res);
          
          //隐藏加载视图
          loadObject.hideLoad();
  
        });
      
      }else{  //切换到登录页面
        proxy.$router.push("/Login");
      }
    */
      
      /*setTimeout(()=>{
        
        getNumberList({
          name:"kl8",
          issueCount:30,
          issueStart:"",
          issueEnd:"",
          dayStart:"",
          dayEnd:"",
          pageNo:1,
          pageSize:10,
          week:"",
          systemType:"PC"
        }).then(res=>{
          console.log("成功：",res);
          
        }).catch((res)=>{
          console.log("网络异常，请重试！",res);
        });
        
        
      },3000)*/
    
    });
  
  
  
  
  
  
  
    return {
  
    };
  
  }
}
</script>


<!--私有的-->
<style scoped>

</style>


<!--全局样式配置-->
<style>

*{
  margin:0;
  padding:0;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

.hdt{
  /*兼容火狐Firefox浏览器*/
  scrollbar-width:none;
}
.hdt::-webkit-scrollbar{
  display:none;
}


/*与树有关的*/
.row-col-tree{
  height:100%;
}
.col-tree{
  height:100%;
  display:flex;
  flex-direction:column;
}
/*树的父元素*/
.treeParent{
  max-height:100%;
  overflow:hidden auto;
}
/*树列表的背景与字体颜色*/
.el-tree{
  color:#FFFFFF;
  background: linear-gradient(to bottom,#5383ef,#5c4cd7);
}

/*点击时高亮，失去焦点后变回原样的样式*/
.el-tree-node:focus>.el-tree-node__content{
  /*background-color:#FF0000;*/
  background-color:#1890ff;
}
/*控制树列表选中后为高亮*/
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
  /*background-color:rgb(135,206,235);*/
  background-color:#1890ff;
  color:#FFFFFF;
}
/*控制树列表的鼠标悬停时为高亮*/
.el-tree-node__content:hover{
  background-color:#1890ff;
  color:#FFFFFF;
}


/*控制主菜单树列表选中后为高亮*/
.el-menu-item.is-active{
  color:#FFFFFF!important;
  background-color:rgba(255,255,255,0.3)!important;
}
/*控制主菜单树列表右边的箭头*/
.el-submenu__title i{
  font-size:16px;
  color:#FFFFFF;
}


/*表格的父级元素*/
.el-col{
  height: 100%;
  display: flex;
  flex-direction: column;
}
/*表格头行*/
.el-table th.el-table__cell.is-leaf{
  color:#FFFFFF;
  background-color:#5BA3ED;
}
/*表格的斑马颜色*/
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  /*background-color:#EBF4F7;*/
  background-color:rgba(135,206,235,0.2);
}
/*表格行的鼠标悬停颜色*/
.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell{
  background-color:rgba(192,192,192,0.3)!important;
}

/*详情的弹窗*/
.el-dialog:not(.is-fullscreen){
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  max-height: 100%;
  overflow: hidden auto;
  margin: unset!important;
  border-radius: 10px;
  padding: 18px;
  display: flex;
  flex-direction: column;
}
/*详情的弹窗的“子类（头部）”*/
.el-dialog:not(.is-fullscreen)>.el-dialog__header{
  padding:unset;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #999999;
  margin-bottom:10px;
}
/*弹窗的关闭按钮*/
.el-dialog__headerbtn{
  position:unset;
  top:unset;
  right:unset;
  font-size: 20px;
}
/*弹窗的主体*/
.el-dialog__body{
  position:relative;
  padding:unset;
  flex: 1;
  max-height: 100%;
  overflow: hidden auto;
}
/*弹窗的主体的“子类”的“子类（项）”*/
.el-dialog__body>.el-form>.el-form-item{
  margin-bottom:23px;
}
/*弹窗的主体的“子类”的“第一个子类（项）”*/
.el-dialog__body>.el-form>.el-form-item:nth-child(1){
  margin-top:10px;
}
/*弹窗的脚部*/
.el-dialog__footer{
  padding:unset;
  margin-top:10px;
}

/*表格上面的操作按钮*/
.el-form--inline{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:10px 0;
}
.el-form-item{
  margin:unset;
}
.el-input--medium{
  margin:0 10px;
}

.el-input--medium{
  margin: unset;
}


/*表格内的按钮*/
.lookDetails{
  padding: 5px!important;
  margin:3px;
  width: min-content;
  
  color: #1890ff!important;
  background-color: rgba(24,144,255,0.1)!important;
  border:1px solid #1890ff!important;
}
.tableEditbutton{
  padding: 5px!important;
  margin:3px;
  width: min-content;
  
  color: #13ce66!important;
  background-color:rgba(19, 206, 102, 0.1)!important;
  border:1px solid #13ce66!important;
}
.tableDeleteButton{
  padding: 5px!important;
  margin:3px;
  width: min-content;
  
  color: #ff4949!important;
  background-color:rgba(255, 73, 73, 0.1)!important;
  border:1px solid #ff4949!important;
}





/*单元格流式排列1--start----------------------------------------------------------*/
/*单条信息*/
.oneInfo_1{
  width:50%;
  float:left;
}
/*单条信息的”子元素“*/
.oneInfo_1>div{
  width:100%;
  display:flex;
  flex-direction:row;
}
/*左右比例：3:7*/
/*单条信息的”子元素“的”第一个子元素（标题）“*/
.oneInfo_1>div>div:nth-child(1){
  min-height:32px;
  flex:3;
  padding:0 10px;
  border-left:1px solid #999999;
  border-bottom:1px solid #999999;
  background-color:rgba(24,144,255,0.2);
  display:flex;
  flex-direction:row;
  align-items:center;
}
/*单条信息的”子元素“的”第二个子元素（内容）“*/
.oneInfo_1>div>div:nth-child(2){
  min-height:32px;
  flex:7;
  padding:0 10px;
  border-left:1px solid #999999;
  border-bottom:1px solid #999999;
  display:flex;
  flex-direction:row;
  align-items:center;
}
/*单条信息的”倒数第一个子元素“的”第二个子元素（内容）“*/
.oneInfo_1:nth-last-child(1)>div>div:nth-child(2){
  border-right:1px solid #999999;
}

/*单元格流式排列1--end----------------------------------------------------------*/


/*单元格流式排列2--start----------------------------------------------------------*/
/*单条信息*/
.oneInfo_2{
  width:438px;
  float:left;
  font-size:16px;
  border-bottom:1px solid #999999;
}
/*单条信息的”子元素“*/
.oneInfo_2>div{
  width:100%;
  display:flex;
  flex-direction:row;
}
/*左右比例：3:7*/
/*单条信息的”子元素“的”第一个子元素（标题）“*/
.oneInfo_2>div>div:nth-child(1){
  min-height:32px;
  flex:3;
  padding:0 0 0 10px;
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items:center;
}
/*单条信息的”子元素“的”第二个子元素（内容）“*/
.oneInfo_2>div>div:nth-child(2){
  min-height:32px;
  flex:7;
  padding:0 10px;
  display:flex;
  flex-direction:row;
  align-items:center;
}
/*单元格流式排列2--end----------------------------------------------------------*/



/*单元格流式排列3--start----------------------------------------------------------*/
/*单条信息*/
.oneInfo_3{
  /*width:438px;*/
  float:left;
  font-size:16px;
  /*border-bottom:1px solid #999999;*/
}
/*单条信息的”子元素“*/
.oneInfo_3>div{
  width:100%;
  display:flex;
  flex-direction:row;
}
/*左右比例：3:7*/
/*单条信息的”子元素“的”第一个子元素（标题）“*/
.oneInfo_3>div>div:nth-child(1){
  width:84px;
  min-height:32px;
  flex:3;
  /*padding:0 0 0 10px;*/
  display:flex;
  flex-direction:row;
  /*justify-content:flex-end;*/
  align-items:center;
}
/*单条信息的”子元素“的”第二个子元素（内容）“*/
.oneInfo_3>div>div:nth-child(2){
  width:206px;
  min-height:32px;
  flex:7;
  padding:0 10px;
  display:flex;
  flex-direction:row;
  align-items:center;
}
/*单条信息的”倒数第一个子元素“的”第二个子元素（内容）“*/
/*.oneInfo_3:nth-last-child(1)>div>div:nth-child(2){
  border-right:1px solid #999999;
}*/

/*单元格流式排列3--end----------------------------------------------------------*/


/*自己的表格的样式*/
.myTable{
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
}
/*表格的头部*/
.myTable>tbody>tr>th{
  width: 6px;  /*宽度：可以为任意宽度，因为选中的是所有th，所以不影响。注意：缺少此值，表格的宽度会不均等！*/
  padding: 6px;
  /*line-height: 1.5;*/
  vertical-align: middle;
  border: 1px solid #999999;
  text-align: center;
  
  background-repeat: no-repeat;
  background-position: center;
}
/*表格的单元格*/
.myTable>tbody>tr>td{
  /*width: 86px;*/
  padding: 6px;
  /*line-height: 1.5;*/
  vertical-align: middle;
  border: 1px solid #999999;
  text-align: center;
}

tr:hover>td{
  background-color:rgba(192,192,192,0.2);
}




/*加载图标的*/
.loading {
}
/*加载图标的指定后代（图标）*/
.loading .el-icon-loading {
  color: #daa520;
  font-size: 60px;
}
/*加载图标的指定后代（提示文字）*/
.loading .el-loading-text {
  color: #ffffff;
  font-size: 16px;
}


/*超出父容器的文字用省略号代替*/
.omitLongText{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}


/*@import url("@/utils/style/tool.css");*/
@import "@/utils/style/tool.css";


/*输入框的*/
.el-input__wrapper{
  padding-left:unset!important;
  padding-right:unset!important;
}
.el-input__wrapper .el-input__inner{
  padding-left: 15px;
  padding-right: 15px;
}


</style>
