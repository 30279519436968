

//用户端的
export const user={
  //一对多
  style:{
    nameColor:"#a6a6a6",  //名字的颜色
    nameSelectedColor:"#ffffff"  //名字的选中颜色,
  },
  //一对一
  //底部导航栏的按钮数据
  buttons:[
    {
      id:"Home",
      //若是单页面路由，则页面路径可无
      //pagePath: "/pages/index/index",
      name: "首页",
      /*iconPath: "/resource/image/icon/index_0.png",
      selectedIconPath: "/resource/image/icon/index_1.png"*/
    }, {
      id:"Cooperation",
      //若是单页面路由，则页面路径可无
      //pagePath: "/pages/release/release",
      name: "合作",
      /*iconPath: "/resource/image/icon/community_0.png",
      selectedIconPath: "/resource/image/icon/community_1.png"*/
    },{
      id:"Service",
      //若是单页面路由，则页面路径可无
      //pagePath: "/pages/release/release",
      name: "服务",
      /*iconPath: "/resource/image/icon/community_0.png",
      selectedIconPath: "/resource/image/icon/community_1.png"*/
    },{
      id:"AboutUs",
      //若是单页面路由，则页面路径可无
      //pagePath: "/pages/release/release",
      name: "关于我们",
      /*iconPath: "/resource/image/icon/community_0.png",
      selectedIconPath: "/resource/image/icon/community_1.png"*/
    }
  ]
};


//技师端的
export const technician={
  //一对多
  style:{
    nameColor:"#666666",  //名字的颜色
    nameSelectedColor:"#536DF9"  //名字的选中颜色,
  },
  //一对一
  //底部导航栏的按钮数据
  buttons:[
    {
      id:"0",
      //若是单页面路由，则页面路径可无
      //pagePath: "/pages/xxx/xxx",
      name: "首页",
      iconPath: "/resource/image/icon/index_0.png",
      selectedIconPath: "/resource/image/icon/index_1.png"
    },{
      id:"1",
      //若是单页面路由，则页面路径可无
      //pagePath: "/pages/xxx/xxx",
      name: "我的",
      iconPath: "/resource/image/icon/user_0.png",
      selectedIconPath: "/resource/image/icon/user_1.png"
    }
  ]
};


//xx的
export const store={
  //一对多
  style:{
    nameColor:"#666666",  //名字的颜色
    nameSelectedColor:"#536DF9"  //名字的选中颜色,
  },
  //一对一
  //底部导航栏的按钮数据
  buttons:[
    {
      id:"0",
      //若是单页面路由，则页面路径可无
      name: "首页",
      iconPath: "/resource/image/icon/index_0.png",
      selectedIconPath: "/resource/image/icon/index_1.png"
    },{
      id:"1",
      //若是单页面路由，则页面路径可无
      //pagePath: "/pages/xxx/xxx",
      name: "我的",
      iconPath: "/resource/image/icon/user_0.png",
      selectedIconPath: "/resource/image/icon/user_1.png"
    }
  ]
};

