<template>
  <div style="position:absolute;left:0;top:0;width:100%;height:100%;display:flex;flex-direction:column;background:linear-gradient(to right,#37234e,#733266,#37234e);color:#ffffff;">
  
    <!-- 卷轴域 -->
    <!--<div class="hdt" style="flex:1;overflow-x:hidden;overflow-y:auto;">
    
    </div>-->
    
    <!--垂直居中-->
    <div style="position:absolute;left:0;top:50%;transform:translateY(-50%);width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center;">
      <div style="font-family:fangsong;font-weight:bold;">
        <div class="xsbdjk" style="background-color:#b21166;color:#ffffff;font-size:86px;letter-spacing:28px;padding:16px 16px;box-shadow:4px 0 10px #000000;">小设备大健康</div>
        <div class="rnrgjk" style="color:#ed57a7;font-size:40px;letter-spacing:8px;margin-top:20px;display:flex;flex-direction:row;justify-content:flex-end;align-items:center;">
          <div style="display:inline-block;width:10px;height:10px;border-radius:10px;background-color:#ed57a7;margin-right:14px;" />
          <span>让男人更健康</span>
        </div>
      </div>
      <div style="padding-right:100px;">
        <div class="xiaoBenTou" />
      </div>
    </div>
    
    <!--二维码-->
    <img src="@/assets/image/gongZhongHaoQrCode.png" style="width:120px;height:120px;position:absolute;right:17%;bottom:8%;" alt="" />
    
    <!--底部栏-->
    <div class="pageBottom" style="/*background-color:#141822;*/color:#8d9199;">
      <span>Copyright ©2019-2024 深圳市小奔科技有限公司 版权所有</span>
      <span @click="goToUrl('http://beian.miit.gov.cn')" style="cursor:pointer;">粤ICP备2022053551号</span>
    </div>
    
  </div>
</template>

<script>
import {onMounted,getCurrentInstance,inject } from "vue";
import {goToUrl} from "@/utils/tool_zhang";

export default {
  name:"Home",
  data(){
    return {
    }
  },
  setup() {
    const { proxy } = getCurrentInstance();
    
    //注入的函数：设置标题
    // inject('setTitle')("小奔XB");
    
    onMounted(async () => {
    
    });
    
    return {
      goToUrl
    }
  }
}
</script>

<style scoped>

/*小奔头图片的动画：脉动 动效*/
@keyframes pulse{
  0%{
    /*-webkit-transform: scaleX(1);
    transform: scaleX(1);*/
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50%{
    /*
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  */
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    
  }
  100%{
    /*-webkit-transform: scaleX(1);
    transform: scaleX(1);*/
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


.xiaoBenTou{
  
  background-image:url("@/assets/image/xiaoBenTou.jpg");
  background-size:100% 100%;
  background-repeat:no-repeat;
  background-position:center center;
  /*background-attachment:fixed;*/
  
  width:400px;
  height:400px;
  
  border-radius:400px;
  
  /*遮罩*/
  -webkit-mask-image: radial-gradient(circle at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
  mask-image: radial-gradient(circle at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
  
  /*动画*/
  
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  
  -webkit-animation-duration:2s;
  animation-duration:2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /*或*/
  /*animation:pulse 2s ease-in-out infinite;*/
}

/*页面的底部*/
.pageBottom{
  position: absolute;
  left: 50%;
  bottom: 0;
  transform:translateX(-50%);
  /*padding:8px 28px;*/
  padding:8px 0;
  white-space:nowrap;
}
.pageBottom>span{
  margin-left:20px;
}
.pageBottom>span:nth-child(1){
  margin-left:unset;
}


/*文字的3D动画*/
@keyframes text3dActivity{
  0%{
    text-shadow:var(--shadow1);
  }
  50%{
    text-shadow:var(--shadow2);
  }
}

/*盒子的3D动画*/
@keyframes box3dActivity{
  0%{
    box-shadow:var(--shadow1);
  }
  50%{
    box-shadow:var(--shadow2);
  }
}

.xsbdjk{
  --myColor:rgba(170, 170, 170,0.8);
  --shadow1:1px 1px var(--myColor), 2px 2px var(--myColor), 3px 3px var(--myColor), 4px 4px var(--myColor), 5px 5px var(--myColor), 6px 6px var(--myColor);
  --shadow2:0px 0px var(--myColor);
  
  text-shadow:var(--shadow1);
  animation:text3dActivity 3s linear infinite;
}
.rnrgjk{
  --myColor:rgba(158, 58, 111,0.8);
  --shadow1:1px 1px var(--myColor), 2px 2px var(--myColor), 3px 3px var(--myColor), 4px 4px var(--myColor), 5px 5px var(--myColor), 6px 6px var(--myColor);
  --shadow2:0px 0px var(--myColor);
  
  text-shadow:var(--shadow1);
  animation:text3dActivity 3s linear infinite;
}
.rnrgjk>div:nth-child(1){
  --myColor:rgba(158, 58, 111,0.8);
  --shadow1:1px 1px var(--myColor), 2px 2px var(--myColor), 3px 3px var(--myColor), 4px 4px var(--myColor), 5px 5px var(--myColor), 6px 6px var(--myColor);
  --shadow2:0px 0px var(--myColor);
  
  box-shadow:var(--shadow1);
  animation:box3dActivity 3s linear infinite;
}
</style>

<style scoped lang="scss">

</style>
